import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Collapse ,Card, Table, Radio, notification} from 'antd';
import jsonfile from './budget.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import _ from "lodash";
import {Bar, BarChart, Area, AreaChart, Legend, CartesianGrid,  XAxis, YAxis, ResponsiveContainer, Tooltip} from "recharts";

const Search = Input.Search;
const Panel = Collapse.Panel;

let  searchView = "";

const BudgetDetails = (props) => {
    const value = 0;

    const history = useHistory();

    const [incomes, setInocmes] = useState([]);
    const [expenses, setExpenses] = useState([]);

    const [form] = Form.useForm();

    const requestID = props.match.params.id;

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const [state, setState] = useState({
        status : 0,
        incomes : [],
        expenses :  [],
        year : requestID,
        totalBudgetIncome : 0,
        totalBudgetExpense : 0,
        totalActualIncome : 0,
        totalActualExpense : 0,
        difBudget : 0,
        difActual : 0,
        incomeExpense : []
    });

    const [incomeExpense,setInocmeExpnese] = useState([]);
    const [loading,setLoading] = useState(false);
    const [local,setLocal] = useState({});
    const [difference,setDifference] = useState(0);
    const [selected,setSelected] = useState([]);
    const [selectedck,setSelectedCK] = useState([]);
    const [panel,setPanel] = useState(
        { vl : 'all' }
    );

    useEffect(() => {
        getBudgetDetails();
    }, []);



    const getBudgetDetails = () => {
        if(requestID >0) {
            document.getElementById("loader").style.display = "block";
            axios.get(Config.apiserver + "accounts/budgetdetails/"+requestID)
                .then(res => {
                    console.log(res)
                    if (res.data.status === 1) {
                        setState({
                            status : res.data.status,
                            incomes : res.data.incomes,
                            expenses :  res.data.expenses,
                            year : res.data.year,
                            totalBudgetIncome : res.data.totalBudgetIncome,
                            totalBudgetExpense : res.data.totalBudgetExpense,
                            totalActualIncome : res.data.totalActualIncome,
                            totalActualExpense : res.data.totalActualExpense,
                            difBudget : res.data.difBudget,
                            difActual : res.data.difActual,
                            incomeExpense : res.data.incomeExpense
                        });

                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    console.log(errors.response.data.errors);
                    document.getElementById("loader").style.display = "none";
                });

        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Invalid request"
            });
            document.getElementById("loader").style.display = "none";
            //history.push("bankreconcile");
        }
    }

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.alltransactions+"/?type=2&"+qsup;
        setState({reqURL: newURL});
    };


    return (
        <>
            <Card title={state.title}>
                {CisUI().showLoading}

                <div className="row">
                    <div className="col-md-12">
                        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">Budget & Actual</h6>
                        <ResponsiveContainer width="100%" height={140}>
                            <BarChart data={state.incomeExpense}
                                       margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="colorIncome" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#023e8a" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#03045e" stopOpacity={0.4}/>
                                    </linearGradient>
                                    <linearGradient id="colorExpnese" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#0096c7" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#0077b6" stopOpacity={0.4}/>
                                    </linearGradient>
                                    <linearGradient id="colorIncomeA" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#48cae4" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#00b4d8" stopOpacity={0.4}/>
                                    </linearGradient>
                                    <linearGradient id="colorExpneseA" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#98c1d9" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#3d5a80" stopOpacity={0.4}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="name" />
                                <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { notation: "compact", compactDisplay: "short" }).format(value)} />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                                <Legend />
                                <Bar type="monotone" dataKey="incomeBudget" name={"Income Budget"} fillOpacity={1} fill="url(#colorIncome)" />
                                <Bar type="monotone" dataKey="incomeActual" name={"Income Actual"} fillOpacity={1} fill="url(#colorIncomeA)" />
                                <Bar type="monotone" dataKey="expenseBudget" name={"Expense Budget"} fillOpacity={1} fill="url(#colorExpnese)" />
                                <Bar type="monotone" dataKey="expenseActual" name={"Expense Actual"} fillOpacity={1} fill="url(#colorExpneseA)" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div className="row">
                    <h1> Summary</h1>
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Total Income</th>
                                    <th>Total Expense</th>
                                    <th>Surplus/ Deficit</th>
                                    <th>Actual Incomes</th>
                                    <th>Actual Expenses</th>
                                    <th>Actual Differences</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{fontWeight : 'bold'}}>{(state.year)}</td>
                                    <td style={{fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(state.totalBudgetIncome)}</td>
                                    <td style={{fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(state.totalBudgetExpense)}</td>
                                    <td style={{fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(state.difBudget)}</td>
                                    <td style={{fontWeight : 'bold'}}>
                                        {CisUI().getCurrencyFormated1(state.totalActualIncome)}
                                    </td>
                                    <td style={{fontWeight : 'bold'}}>
                                        {CisUI().getCurrencyFormated1(state.totalActualExpense)}
                                    </td>
                                    <td style={{fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(state.difActual)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <h4>Incomes</h4>
                    </div>
                    <div className="col-md-6" style={{textAlign: 'right'}}>
                       B = Budget , A = Actual
                    </div>
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Account</th>
                                    <th style={{width : "80px"}}>Total</th>
                                    {_.times(12, (i) => (
                                        <th style={{width : "60px"}} key={i}>{monthNames[i]}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    state.incomes.map((items,index)=> {
                                        return <tr>
                                            <td>{items.account_number}-{items.account_name}</td>
                                            <td>
                                               B : {CisUI().getCurrencyFormated1(items.total.budget)}<br/>
                                               A : {CisUI().getCurrencyFormated1(items.total.actual)}
                                            </td>
                                            {_.times(12, (i) => (
                                                <td style={{width : "60px"}}>
                                                    B : {CisUI().getCurrencyFormated1(items["month"+(i + 1)]["budget"])} <br/>
                                                    A : {CisUI().getCurrencyFormated1(items["month"+(i + 1)]["actual"])}
                                                </td>
                                            ))}
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <h4>Expenses</h4>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Account</th>
                                    <th style={{width : "80px"}}>Total</th>
                                    {_.times(12, (i) => (
                                        <th style={{width : "60px"}} key={i}>{monthNames[i]}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    state.expenses.map((items,index)=> {
                                        return <tr>
                                            <td>{items.account_number}-{items.account_name}</td>
                                            <td>
                                                B : {CisUI().getCurrencyFormated1(items.total.budget)}<br/>
                                                A : {CisUI().getCurrencyFormated1(items.total.actual)}
                                            </td>
                                            {_.times(12, (i) => (
                                                <td style={{width : "60px"}}>
                                                    B : {CisUI().getCurrencyFormated1(items["month"+(i + 1)]["budget"])} <br/>
                                                    A : {CisUI().getCurrencyFormated1(items["month"+(i + 1)]["actual"])}
                                                </td>
                                            ))}
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div style={{textAlign : 'center', display : 'none'}}>
                        <button type="button" className="btn btn-primary">Print</button>
                    </div>

                </div>


            </Card>
        </>
    );
};

export default BudgetDetails;