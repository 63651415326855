import axios from 'axios';
// import { setupCache } from 'axios-cache-adapter'

// Create `axios-cache-adapter` instance
// const cache = setupCache({
//   maxAge: 15 * 60 * 1000
// })
import  localStorage  from  "react-secure-storage";


const token = localStorage.getItem('token');

const axiosinstance = axios.create({
  // adapter: cache.adapter,
  baseURL: "https://api.penterp.com/api/",
  // baseURL: "https://sandboxapi.penterp.com/api/",
  // baseURL: "http://localhost:8000/api/",
  headers: {
    'Content-Type': 'application/json',
    'Authorization': token,
  }
});

export default axiosinstance;



// export const HeaderRequest = () => {
//   const token = localStorage.getItem('token');
//   return {headers : {Authorization : token}};
// }