import {Form, Row, Col, Input, Button, Card, Select, notification, DatePicker} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './localexpenses.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import InputOnly from "../../../../util/InputOnly";
import alert from "../../../../routes/components/feedback/Alert";
import moment from "moment";
const { Option } = Select;


const NewExpenses = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);
    const [inputList, setInputList] = useState([]);
    const [msg, setMsg] = useState("");
    const [lineitems, setLineItems] = useState([]);


    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.add;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        console.log("ExpenseData : "+inputList);

        const newArray = inputList.filter(value => JSON.stringify(value) !== '{}');
        document.getElementById("loader").style.display = "block";
        console.log("AfterFilter : "+inputList);
        values['expenses'] = newArray;
        let msg = "Sorry! request not processed, please try again";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors);
                document.getElementById("loader").style.display = "none";
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
            });
    };

    const dataOptions = SelectData("donation/expensetype?area=6");

    // useEffect(() => {
    //     setInputList(dataOptions);
    // }, []);

    const Tc = dataOptions.length;
    const dataex = [];
    dataOptions.map((value,index) => {
        dataex[index] = {
            expensetype : value.id,
            amount : '',
            note : '',
            file : ''
        };
    });

    useEffect(() => {
        setInputList(dataex);
    }, []);


    const onLocalChange = e => {
       const month = form.getFieldValue('month');
       const localID = form.getFieldValue('local_id');
       const mnt = moment(month).format('YYYYMM');
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+"donation/lcexpneseopenbymonth/"+mnt+"?req_id="+localID+"&area=Local")
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if(res.data.data.length > 0) {
                    setMsg("Note: Expenses have been entered for the selected month. Submitting a new entry will overwrite the existing one.");
                    setLineItems(res.data.data);
                    const data = res.data.data;
                    dataOptions.map((inx,i)=>{
                        const fl = data.filter(item => item.account_id === inx.id);
                        if(fl.length > 0) {
                            dataOptions[i].amount = fl[0].amount;
                            dataOptions[i].note = fl[0].notes;
                        }
                    });
                }
                else {
                    setMsg("");
                    setLineItems([]);
                    dataOptions.map((inx,i)=>{
                        dataOptions[i].amount = null;
                        dataOptions[i].note = null;
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                setMsg("");
                setLineItems([]);
                dataOptions.map((inx, i) => {
                    dataOptions[i].amount = null;
                    dataOptions[i].note = null;
                });
                console.log(errors);
                document.getElementById("loader").style.display = "none";
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
            });
    }

    const onMonthChange = e => {
        const month = form.getFieldValue('month');
        let localID = 0;
        if(CisUI().getUserInfo('comarea') === 6) {
            localID = CisUI().getUserInfo('local_id');
        }
        else {
            localID = form.getFieldValue('local_id');
        }
        const mnt = moment(month).format('YYYYMM');

        if(localID > 0 && mnt > 0) {

            document.getElementById("loader").style.display = "block";
            axios.get(Config.apiserver + "donation/lcexpneseopenbymonth/" + mnt + "?req_id=" + localID + "&area=Local")
                .then(res => {
                    console.log(res)
                    document.getElementById("loader").style.display = "none";
                    if (res.data.data.length > 0) {
                        setMsg("Note: Expenses have been entered for the selected month. Submitting a new entry will overwrite the existing one.");
                        setLineItems(res.data.data);
                        const data = res.data.data;
                        dataOptions.map((inx, i) => {
                            const fl = data.filter(item => item.account_id === inx.id);
                            if (fl.length > 0) {
                                dataOptions[i].amount = fl[0].amount;
                                dataOptions[i].note = fl[0].notes;
                            }
                        });
                    } else {
                        setMsg("");
                        setLineItems([]);
                        dataOptions.map((inx, i) => {
                            dataOptions[i].amount = null;
                            dataOptions[i].note = null;
                        });
                    }
                    setLoading({loading: false});
                })
                .catch(errors => {
                    setMsg("");
                    setLineItems([]);
                    dataOptions.map((inx, i) => {
                        dataOptions[i].amount = null;
                        dataOptions[i].note = null;
                    });
                    console.log(errors);
                    document.getElementById("loader").style.display = "none";
                    // msg = (errors);
                    setLoading({loading: false});
                    //setResponse([]);
                });
        }
    }


    //console.log("UserData : "+userData);

    const handleInputChange = (e, index) => {
        if(e && e.target) {
            const {name, value} = e.target;

            const list = [...inputList, { }];

            list[index]["expensetype"] = dataOptions[index].id;
            list[index][name] = value;
            setInputList(list);
            //list[index]["expensetype"] = dataOptions[index].id;
            setInputList(list);
           // console.log(JSON.stringify(inputList));
        }
        else {
            const list = [...inputList];
            list[index]["expensetype"] = e;
            setInputList(list);
        }
    };

// handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

// handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { }]);
    };

    const locallist = RemoteJson("locallist");


    return (
        <Card title={Title}>
            {
                isEdit === 1
                    ? CisUI().addAction("../../"+jsonfile.urls.list)
                    : CisUI().addAction("../"+jsonfile.urls.list)
            }
            {CisUI().showLoading}
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {/*{InputOnly(jsonfile.input,userData,isEdit)}*/}

                <Row gutter={24}>
                    <div className="col-md-4 col-sm-6">
                        <Form.Item
                            name="date"
                            label="Date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a date"
                                },
                            ]}
                        >
                            <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat}
                                        className="gx-mb-3 gx-w-100"
                                        placeholder="Date"/>
                        </Form.Item>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <Form.Item
                            name="month"
                            label="Month"
                            initialValue={moment()}
                            onChange={()=>onLocalChange("date")}
                            rules={[
                                {
                                    required: true,
                                    message: "Select a date"
                                },
                            ]}
                        >
                            <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} onChange={onMonthChange} picker="month" format={"YYYY-MM"} className="gx-mb-3 gx-w-100"
                                        placeholder={"Month"}/>
                        </Form.Item>
                    </div>

                    {
                        CisUI().getUserInfo('comarea') <= 5 ?
                            <div className="col-md-4 col-sm-6">
                                <Form.Item
                                    name="local_id"
                                    label="Local"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a local"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a local"
                                        optionFilterProp="children"
                                        onChange={onLocalChange}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            locallist.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div> : <></>
                    }



                </Row>

                <Row>
                    <div className={"col-md-12"}>
                        <h3 style={{color: "red"}}>{msg}</h3>
                    </div>
                </Row>

                <Row gutter={24}>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Account Name</th>
                                <th>Amount</th>
                                <th>Note</th>
                                <th>Attachment</th>
                            </tr>
                        </thead>

                    {dataOptions.map((x, i) => {
                        return (
                            <>
                                <tr>
                                    <td>{x.name}
                                    <input type="hidden" id={"expensetype_"+x.id} name="expensetype" value={x.id} />
                                    </td>
                                    <td>
                                        <input
                                            className="ant-input"
                                            name="amount"
                                            id={"amount_"+x.id}
                                            placeholder="Amount"
                                            value={x.amount}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className="ant-input"
                                            name="note"
                                            id={"note_"+x.id}
                                            placeholder="Enter a note"
                                            value={x.note}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className="ant-input"
                                            name="file"
                                            id={"file_"+x.id}
                                            type="file"
                                            value={x.file}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </td>
                                </tr>
                            </>
                        );
                    })}

                    </table>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>

            </Form>


        </Card>
    );
};

export default NewExpenses;