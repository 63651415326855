import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Checkbox } from "antd";
import React, {useEffect, useState} from "react";
// import Particles from "react-particles-js";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { userSignIn } from "../appRedux/actions";
import config from '../util/config'
import "./sForm.css";

import InfoView from "components/InfoView";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector(({ auth }) => auth.authUser);

  const [user,setUser] = useState({
    email : "",
    password : "",
  });

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // for(var i=0, len=localStorage.length; i<len; i++) {
  //   var key = localStorage.key(i);
  //   var value = localStorage[key];
  //   console.log(key + " => " + value);
  // }

  const onChange = (e) => {
    if(e.target.name === 'email') {
      setUser({
        email : e.target.value,
        password : user.password,
      })
    }
    else if(e.target.name === 'password') {
      setUser({
        email : user.email,
        password : e.target.value,
      })
    }

  }

  const onFinish = (values) => {
    console.log("finish",values)
    values['email'] = user.email;
    values['password'] = user.password;
    dispatch(userSignIn(values));
  };

  useEffect(() => {
    if (authUser !== null) {
      history.push("/");
    }
  }, [authUser]);

  return (
    <>
      {/* <div id="particles-js"></div> */}
      <div className="s_form_area">

        <div className="s_form_left">
          <img
            alt="example"
            src="/assets/images/penterpv1.png" className="s_login_logo"
          />
          <div className="s_left_text">

          </div>

          <div className="copy_right_area">{config.footerText}</div>
        </div>



        <div className="s_form_right">
          {/* <div className="elememt2_img" style={{   backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bgg2.png'})`,  width: "346px",height: "330px",right: "52px",
        position: "absolute",top: "-160px", transform: "rotate(20deg)"}}></div> */}
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-6 offset-md-3">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>

                <div class="mb-3">
                  <label class="form-label" style={{color : 'white'}}>Username</label>
                  <input type="text" required name="email" onChange={onChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div class="mb-3">
                  <label className="form-label" style={{color : 'white'}}>Password</label>
                  <input type="password" required name="password" onChange={onChange} className="form-control" />
                </div>

                <div className="sub_link" style={{textAlign : 'right'}}>
                  <Link to="/forgotpassword">Forgot Password ?</Link>
                </div>

                <button type="submit" className="btn btn_primary text-right s_btn" ><IntlMessages id="app.userAuth.signIn" /></button>
                {/* <div className="elememt3_img" style={{   backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bgg1.png'})`,  width: "250px",height: "208px",right: "0px", position: "absolute",bottom: "0px", transform: "rotate(-20deg)"}}></div> */}
              </Form>

            </div>
          </div>
          <div className="copy_left_area">{config.footerText}</div>
        </div>
        <InfoView/>
      </div>
    </>
  );
};

export default SignIn;
