import React, {useState} from "react";
import {Form, Col, Input, Collapse, Card, notification} from 'antd';
import jsonfile from './income.json'
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

const Panel = Collapse.Panel;

const IncomeStatement = () => {
    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.list,
        filtervalue: '',
        subtitle: '',
    });

    const [header, setHeader] = useState("");
    const [period, setPeriod] = useState("");

    const [hide, setHide] = useState([]);
    const [company, setCompany] = useState({});

    const ref = React.createRef();
    const formRef = React.createRef();

    const dt = CisUI().getCurrentYearDateRange;

    const [dateset,setDate] = useState(dt);

    const [data, setData] = useState([]);
    const [resp, setResp] = useState({
        status: 0,
        msg: "General Ledger",
        company: {},
        incomes: [],
        expenses: [],
        total_income: 0,
        total_expense: 0,
        profit: 0,
        beginning_balance: 0,
        ending_balance: 0
    });

    // useEffect(() => {
    //     if(CisUI().getUserInfo('com_id') > 0) {
    //         getLedgerData();
    //     }
    // }, []);

    const getDetailsLink = (source,id) => {
        return "../../accounts/ledger?account_id="+id+"&date="+dateset;
    }

    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if (url !== "") {
            urls = url;
        }
        axios.get(urls)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                    setCompany(res.data.company);
                    setHeader(res.data.company.legal_name);
                    setResp(res.data);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    }

    const history = useHistory();

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            invisible: true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        debit: {
            text: <IntlMessages id="Debit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        credit: {
            text: <IntlMessages id="Credit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        }
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if (list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name}/>
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    // wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    setPeriod("Between " + CisUI().DateFormat(dt[0]) + " and " + CisUI().DateFormat(dt[1]));
                    setDate((dt))
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.list + "?" + qsup;
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };

    const handleOnFilter = ({target: {name, value}}) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue: "test"});
    }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search, "", 1,"date",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const print = () => {
        window.open(Config.baseurl + "reports/incomestatementprint.html?url=" + window.btoa(state.reqURL) + "&filename=income_statement&data=incomestatementData&subtitle=" + period + "&rptype=incomestatement", "_blank");
    }

    const listAction = () =>
        (
            <div className="no-print">
                <a id="rploader" style={{width: '40px', height: '40px', display: 'none'}}>
                    <img src="/assets/images/loader.svg" style={{width: '40px', height: '40px'}} alt="loader"/> Please
                    wait ...
                </a>
                {/*<button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData()} title="Export to Excel"><i*/}
                {/*    className="fas fa-file-excel"/></button>*/}
                {/*<button className="btn btn-export-pdf btn-circle-md" onClick={exportToPDF} title="Export to PDF"><i*/}
                {/*    className="fas fa-file-pdf"/></button>*/}
                {/*<button className="btn btn-export-word btn-circle-md" onClick={exportToDocs} title="Export MS Word"><i*/}
                {/*    className="fas fa-file-word"/></button>*/}
                <button onClick={print} className="btn btn-export-print btn-circle-md" title="Print"><i
                    className="fas fa-print"/></button>
            </div>
        );

    const getExtra = (
        <>
            {listAction()}
        </>
    );

    const setCollapse = (id) => {
        console.log("Slcct: ", id);
        if (hide.indexOf(id) > -1) {
            var index = hide.indexOf(id); // Let's say it's Bob.
            hide.splice(index, 1);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-down");
            var row = document.getElementsByClassName("disp_" + id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'inherit';
                row[i].removeAttribute('style');
            }
        } else {
            const hd = hide;
            hd.push(id);
            setHide(hd);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-right")
            var row = document.getElementsByClassName("disp_" + id);
            var i;
            for (i = 0; i < row.length; i++) {
                row[i].style.display = 'none';
            }
        }
        console.log("hides : ", hide);
    }

    let totalDebit = 0;
    let totalCredit = 0;

    return (
        <>
            {searchView}
            <Card extra={getExtra}>
                {CisUI().showLoading}
                {resp.status === 1 ?
                    <div>
                        <div className="table-responsive">
                            <div style={{textAlign: 'center'}}>
                                <h2>{header}</h2>
                                <h4>Income Statement</h4>
                                <h4>{period}</h4>
                            </div>

                            <table className="statement table mx-auto w-auto">
                                <thead>
                                <tr>
                                    <th style={{width: '320px'}}>Description</th>
                                    <th style={{textAlign: 'right', width: '120px'}}>Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{fontWeight: 'bold'}}>Income :</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>&nbsp;</td>
                                </tr>
                                {
                                    resp.incomes.map((itmes, index) => {
                                        return <tr>
                                            <td className="clmintent no-border"
                                                style={{fontWeight: 'normal', paddingLeft: '40px'}}>
                                                <div className="subItemText">{itmes.account}</div>
                                            </td>
                                            <td className="no-border" style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><Link to={getDetailsLink("Ledger",itmes.id)}>{CisUI().getCurrencyFormated1(itmes.amount)}</Link></td>
                                        </tr>
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>Total Income :</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.total_income)}</td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight: 'bold'}}>Expenses :</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>&nbsp;</td>
                                </tr>
                                {
                                    resp.expenses.map((itmes, index) => {
                                        return <tr>
                                            <td className="clmintent"
                                                style={{fontWeight: 'normal', paddingLeft: '40px'}}>
                                                <div className="subItemText">{itmes.account}</div>
                                            </td>
                                            <td style={{
                                                textAlign: 'right',
                                                fontWeight: 'normal'
                                            }}><Link to={getDetailsLink("Ledger",itmes.id)}>{CisUI().getCurrencyFormated1(itmes.amount)}</Link></td>
                                        </tr>
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}}>Total Expenses</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.total_expense)}</td>
                                </tr>
                                <tr>
                                    <td style={{fontWeight: 'bold'}}>Profit/Loss</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.profit)}</td>
                                </tr>
                                <tr style={{display: 'none'}}>
                                    <td style={{fontWeight: 'bold'}}>Net Asset -Beginning</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.beginning_balance)}</td>
                                </tr>
                                <tr className="showTotalBorderDouble" style={{display: 'none'}}>
                                    <td style={{fontWeight: 'bold'}}>Net Asset -Ending</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(resp.ending_balance)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : ""}

            </Card>
        </>
    );
};

export default IncomeStatement;